//
// footer.scss
//
.footer-subscribe {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1)
}

.bg-footer {
    background-color: #121212;
    .footer-link {
        a {
            color: rgba(255, 255, 255, 0.5);
            line-height: 38px;
            transition: all 0.5s;
            &:hover {
                color: $white;
            }
        }
    }
    .footer-subcribe {
        input {
            padding: 12px 20px;
            width: 100%;
            font-size: 14px;
            border: none;
            outline: none !important;
            padding-right: 75px;
            padding-left: 15px;
            border-radius: 5px;
            font-weight: 500;
        }
        button {
            position: absolute;
            top: 0px;
            right: 0px;
            outline: none !important;
            border-radius: 0px 5px 5px 0px;
            font-size: 14px;
            padding: 11px 20px;
        }
        form {
            position: relative;
            max-width: 400px;
        }
    }
}

.footer-social {
    a {
        color: rgba(255, 255, 255, 0.5);
        font-size: 18px;
        transition: all 0.5s;
        display: inline-block;
        &:hover {
            color: $white;
        }
    }
}

.footer-alt {
    background-color: #050404;
}